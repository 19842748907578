var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.currentCourse
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c(
              "div",
              { staticClass: "kt-portlet" },
              [
                _c(
                  "div",
                  { staticClass: "kt-portlet__head kt-portlet__head--lg" },
                  [
                    _c("div", { staticClass: "kt-portlet__head-label" }, [
                      _c("h3", { staticClass: "kt-portlet__head-title" }, [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "pages.teacher.assignments.newAssignment"
                                )
                              ) +
                              " - " +
                              _vm._s(_vm.currentCourse.googleCourseName) +
                              " "
                          ),
                        ]),
                        _vm.teacher
                          ? _c("small", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.teacher.lastName) +
                                  ", " +
                                  _vm._s(_vm.teacher.firstName) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                      _c("div", { staticClass: "kt-portlet__head-group" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-sm btn-icon btn-clean btn-icon-md",
                            attrs: { href: "#", title: "Go back" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.back()
                              },
                            },
                          },
                          [_c("i", { staticClass: "la la-close" })]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "b-overlay",
                  {
                    attrs: {
                      "no-center": "",
                      show: _vm.saving || _vm.createdAssignments.length > 0,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function () {
                            return [
                              _vm.saving
                                ? _c(
                                    "div",
                                    { staticClass: "kt-portlet__body" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "stopwatch",
                                              "font-scale": "3",
                                              animation: "cylon",
                                            },
                                          }),
                                          _c("h6", { staticClass: "mt-4" }, [
                                            _vm._v(
                                              " Please wait while we create your assignment... "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.createdAssignments.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "kt-portlet__body" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "alert alert-solid-success alert-bold",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "alert-text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.createdAssignments
                                                      .length
                                                  ) +
                                                  " assignments were created. Click the links below to view them in google classroom. "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tab-content" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "kt-notification" },
                                            _vm._l(
                                              _vm.createdAssignments,
                                              function (assignment) {
                                                return _c(
                                                  "a",
                                                  {
                                                    key: `googleCourseWorkId_${assignment.googleCourseWorkId}`,
                                                    staticClass:
                                                      "kt-notification__item",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.openAssignment(
                                                          assignment
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "kt-notification__item-icon",
                                                      },
                                                      [
                                                        _c("SVGIcon", {
                                                          staticClass:
                                                            "kt-svg-icon kt-svg-icon--brand",
                                                          attrs: {
                                                            name: "assignments",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "kt-notification__item-details",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-notification__item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  assignment.courseWorkTitle
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-notification__item-time",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  assignment.googleCourseName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 mt-4 text-center",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-secondary btn-brand",
                                                attrs: {
                                                  type: "button",
                                                  "aria-label": "Go back",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.back()
                                                  },
                                                },
                                              },
                                              [_vm._v(" Close ")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2021128439
                    ),
                  },
                  [
                    _c("div", { staticClass: "kt-portlet__body pt-4" }, [
                      _c(
                        "div",
                        { staticClass: "row form-group validated" },
                        [
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "pages.teacher.assignments.markingPeriod"
                                  )
                                )
                              ),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.courseWork.schoolTermMarkingPeriodId,
                                    expression:
                                      "courseWork.schoolTermMarkingPeriodId",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.errors.markingPeriod,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.courseWork,
                                      "schoolTermMarkingPeriodId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(
                                _vm.markingPeriodSelect,
                                function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.value,
                                      domProps: { value: option.value },
                                    },
                                    [_vm._v(" " + _vm._s(option.text) + " ")]
                                  )
                                }
                              ),
                              0
                            ),
                            _vm.errors.markingPeriod
                              ? _c(
                                  "span",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.errors.markingPeriod) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("label", { staticClass: "w-100" }, [
                              _c("span", { staticClass: "kt-font-danger" }, [
                                _vm._v("* "),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("pages.teacher.assignments.category")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.courseWork.gradeTemplateCategoryId,
                                    expression:
                                      "courseWork.gradeTemplateCategoryId",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.errors.courseWorkCategory,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.courseWork,
                                      "gradeTemplateCategoryId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.gradeTemplateCategories, function (t) {
                                return _c(
                                  "option",
                                  {
                                    key: `gradeTemplateCategories_${t.gradeTemplateCategoryId}`,
                                    domProps: {
                                      value: t.gradeTemplateCategoryId,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(t.categoryName) + " ")]
                                )
                              }),
                              0
                            ),
                            _vm.errors.courseWorkCategory
                              ? _c(
                                  "span",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.errors.courseWorkCategory) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Due Date")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          "button-only": "",
                                          autocomplete: "off",
                                          "reset-button": "",
                                          locale: "en-US",
                                        },
                                        model: {
                                          value: _vm.courseWork.dueDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "dueDate",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.dueDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "form-control kt-input",
                                    class: { "is-invalid": _vm.errors.dueDate },
                                    attrs: {
                                      type: "date",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.dueDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.courseWork, "dueDate", $$v)
                                      },
                                      expression: "courseWork.dueDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Due Time")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-timepicker", {
                                        attrs: {
                                          "show-seconds": false,
                                          hour12: "",
                                          "button-only": "",
                                          "reset-button": "",
                                          locale: "en",
                                        },
                                        model: {
                                          value: _vm.courseWork.dueTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "dueTime",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.dueTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    class: _vm.errors.dueDate
                                      ? "is-invalid form-control kt-input"
                                      : "form-control kt-input",
                                    attrs: {
                                      type: "time",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.dueTime,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.courseWork, "dueTime", $$v)
                                      },
                                      expression: "courseWork.dueTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.errors.dueDate
                            ? _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "span",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.dueDate) + " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _c("label", [_vm._v("Type")]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.courseWork.courseWorkType,
                                    expression: "courseWork.courseWorkType",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.courseWork,
                                      "courseWorkType",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.courseWorkTypes, function (t) {
                                return _c(
                                  "option",
                                  {
                                    key: `courseWorkTypes_${t.value}`,
                                    domProps: { value: t.value },
                                  },
                                  [_vm._v(" " + _vm._s(t.display) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pages.teacher.assignments.weight")
                                )
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.courseWork.courseWorkWeight,
                                  expression: "courseWork.courseWorkWeight",
                                },
                              ],
                              staticClass: "form-control",
                              class: { "is-invalid": _vm.errors.weight },
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.courseWork.courseWorkWeight,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.courseWork,
                                    "courseWorkWeight",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.errors.weight
                              ? _c(
                                  "div",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.weight) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pages.teacher.assignments.maxPoints")
                                )
                              ),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.courseWork.maxPoints,
                                  expression: "courseWork.maxPoints",
                                },
                              ],
                              staticClass: "form-control",
                              class: { "is-invalid": _vm.errors.maxPoints },
                              attrs: { type: "text" },
                              domProps: { value: _vm.courseWork.maxPoints },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.courseWork,
                                    "maxPoints",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.errors.maxPoints
                              ? _c(
                                  "div",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.maxPoints) + " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("generic.labels.topic"))),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.courseWork.extTopicId,
                                    expression: "courseWork.extTopicId",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.courseWork,
                                      "extTopicId",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.googleTopics, function (t) {
                                return _c(
                                  "option",
                                  {
                                    key: `googleCourseTopic_${t.googleCourseTopicId}`,
                                    domProps: { value: t.extTopicId },
                                  },
                                  [_vm._v(" " + _vm._s(t.topicName) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("div", { staticClass: "col-md-12" }, [
                            _vm.courseWork.courseWorkType ==
                            "MULTIPLE_CHOICE_QUESTION"
                              ? _c("label", [
                                  _c(
                                    "span",
                                    { staticClass: "kt-font-danger" },
                                    [_vm._v("* ")]
                                  ),
                                  _vm._v(" Multiple Choice Question "),
                                ])
                              : _vm.courseWork.courseWorkType ==
                                "SHORT_ANSWER_QUESTION"
                              ? _c("label", [
                                  _c(
                                    "span",
                                    { staticClass: "kt-font-danger" },
                                    [_vm._v("* ")]
                                  ),
                                  _vm._v(" Short Answer Question "),
                                ])
                              : _c("label", [
                                  _c(
                                    "span",
                                    { staticClass: "kt-font-danger" },
                                    [_vm._v("* ")]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "pages.teacher.assignments.title"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.courseWork.courseWorkTitle,
                                  expression: "courseWork.courseWorkTitle",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.errors.courseWorkTitle,
                              },
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.courseWork.courseWorkTitle,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.courseWork,
                                    "courseWorkTitle",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm.errors.courseWorkTitle
                              ? _c(
                                  "div",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.errors.courseWorkTitle) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm.courseWork.courseWorkType ==
                          "MULTIPLE_CHOICE_QUESTION"
                            ? _c(
                                "div",
                                { staticClass: "col-12 pt-5" },
                                _vm._l(
                                  _vm.courseWork.multipleChoiceQuestion.choices,
                                  function (choice, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: `choice_${idx}`,
                                        staticClass: "form-group row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-md-1 d-none d-sm-block",
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "kt-badge kt-badge--primary kt-badge--dot pull-right",
                                              staticStyle: {
                                                opacity: "0.5",
                                                "margin-top": "15px",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c("div", { staticClass: "col-md-6" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-input-icon kt-input-icon--right",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: choice.text,
                                                    expression: "choice.text",
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                class: { "is-invalid": false },
                                                attrs: {
                                                  type: "text",
                                                  placeholder: `Multiple Choice Option ${
                                                    idx + 1
                                                  }`,
                                                },
                                                domProps: {
                                                  value: choice.text,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      choice,
                                                      "text",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "kt-input-icon__icon kt-input-icon__icon--right",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.removeChoiceAtIndex(
                                                        idx
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _c("i", {
                                                      staticClass:
                                                        "la la-close",
                                                    }),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("div", { staticClass: "col-md-5" }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm.courseWork.courseWorkType == "ASSIGNMENT"
                            ? [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("label", [
                                    _vm._v("Assignment Description"),
                                  ]),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.courseWork.courseWorkDescription,
                                        expression:
                                          "courseWork.courseWorkDescription",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { rows: "3" },
                                    domProps: {
                                      value:
                                        _vm.courseWork.courseWorkDescription,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.courseWork,
                                          "courseWorkDescription",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            : _vm._e(),
                          _c("div", { staticClass: "col-md-3" }, [
                            _c("label", [
                              _vm._v(_vm._s(_vm.$t("generic.labels.state"))),
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.courseWork.courseWorkState,
                                    expression: "courseWork.courseWorkState",
                                  },
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.courseWork,
                                      "courseWorkState",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              _vm._l(_vm.courseWorkStates, function (state) {
                                return _c(
                                  "option",
                                  {
                                    key: state.value,
                                    domProps: { value: state.value },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            `pages.teacher.assignments.${state.display.toLowerCase()}`
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Publish Date")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          "button-only": "",
                                          autocomplete: "off",
                                          "reset-button": "",
                                          locale: "en-US",
                                        },
                                        model: {
                                          value: _vm.courseWork.publishDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "publishDate",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.publishDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "form-control kt-input",
                                    class: {
                                      "is-invalid": _vm.errors.publishDate,
                                    },
                                    attrs: {
                                      type: "date",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.publishDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseWork,
                                          "publishDate",
                                          $$v
                                        )
                                      },
                                      expression: "courseWork.publishDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Publish Time")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-timepicker", {
                                        attrs: {
                                          "show-seconds": false,
                                          hour12: "",
                                          "reset-button": "",
                                          "button-only": "",
                                          locale: "en",
                                        },
                                        model: {
                                          value: _vm.courseWork.publishTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "publishTime",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.publishTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    class: _vm.errors.publishDate
                                      ? "is-invalid form-control kt-input"
                                      : "form-control kt-input",
                                    attrs: {
                                      type: "time",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.publishTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseWork,
                                          "publishTime",
                                          $$v
                                        )
                                      },
                                      expression: "courseWork.publishTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.errors.publishDate
                            ? _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "span",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.publishDate) + " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "col-12 pt-4" }, [
                            _c("label", { staticClass: "kt-font-bold w-100" }, [
                              _vm._v(" Assign to Google Courses: "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "form-text text-muted pull-right",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.selectAll()
                                        },
                                      },
                                    },
                                    [_vm._v("All")]
                                  ),
                                  _vm._v(" | "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.selectNone()
                                        },
                                      },
                                    },
                                    [_vm._v("None")]
                                  ),
                                  _vm._v(" | "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.selectInvert()
                                        },
                                      },
                                    },
                                    [_vm._v("Invert")]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "kt-checkbox-inline" },
                              _vm._l(
                                _vm.selectedGoogleCourses,
                                function (course, idx) {
                                  return _c(
                                    "label",
                                    {
                                      key: `label_${idx}_${course.googleCourseId}`,
                                      staticClass:
                                        "kt-checkbox col-xl-2 col-lg-3 col-md-4 col-sm-6 no-wrap-line",
                                      class: course.disabled
                                        ? "kt-checkbox--primary"
                                        : "kt-checkbox--success",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: course.selected,
                                            expression: "course.selected",
                                          },
                                        ],
                                        attrs: {
                                          disabled: course.disabled,
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            course.selected
                                          )
                                            ? _vm._i(course.selected, null) > -1
                                            : course.selected,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = course.selected,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    course,
                                                    "selected",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    course,
                                                    "selected",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(course, "selected", $$c)
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(course.googleCourseName) +
                                          " "
                                      ),
                                      _c("span"),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "kt-portlet__foot" }, [
                      _c(
                        "div",
                        { staticClass: "kt-form__actions pull-right pb-3" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              class: {
                                "kt-spinner kt-spinner--sm kt-spinner--light":
                                  _vm.saving,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveAssignment(
                                    _vm.courseWork.googleCourseWorkId
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " Create Assignment" +
                                  _vm._s(
                                    _vm.selectedGoogleCourses.filter(
                                      (course) => course.selected
                                    ).length == 1
                                      ? ""
                                      : "s"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ])
    : _c("div", [_vm._m(0)])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet__body" }, [
          _c(
            "div",
            {
              staticClass: "alert alert-outline-primary",
              attrs: { role: "alert" },
            },
            [
              _c("div", { staticClass: "alert-icon" }, [
                _c("i", { staticClass: "flaticon-warning" }),
              ]),
              _c("div", { staticClass: "alert-text" }, [
                _vm._v(" This course is not linked to google classroom. "),
              ]),
            ]
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }