<template>
<div v-if="currentCourse">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        <span>
                            {{ $t('pages.teacher.assignments.newAssignment') }} - {{ currentCourse.googleCourseName }}
                        </span>

                        <small v-if="teacher">
                            {{ teacher.lastName }}, {{ teacher.firstName }}
                        </small>
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                    <div class="kt-portlet__head-group">
                        <a
                            href="#"
                            title="Go back"
                            class="btn btn-sm btn-icon btn-clean btn-icon-md"
                            @click.stop.prevent="back()"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
            </div>

            <b-overlay
                no-center
                :show="saving || createdAssignments.length > 0"
            >
                <template #overlay>
                    <div
                        v-if="saving"
                        class="kt-portlet__body"
                    >
                        <div class="text-center">
                            <b-icon
                                icon="stopwatch"
                                font-scale="3"
                                animation="cylon"
                            />

                            <h6 class="mt-4">
                                Please wait while we create your assignment...
                            </h6>
                        </div>
                    </div>

                    <div
                        v-if="createdAssignments.length > 0"
                        class="kt-portlet__body"
                    >
                        <div class="alert alert-solid-success alert-bold">
                            <div class="alert-text">
                                {{ createdAssignments.length }} assignments were created. Click the links below to view
                                them in google classroom.
                            </div>
                        </div>

                        <div class="tab-content">
                            <div class="kt-notification">
                                <a
                                    v-for="assignment in createdAssignments"
                                    :key="`googleCourseWorkId_${assignment.googleCourseWorkId}`"
                                    class="kt-notification__item"
                                    href="#"
                                    @click.stop.prevent="openAssignment(assignment)"
                                >
                                    <div class="kt-notification__item-icon">
                                        <SVGIcon
                                            class="kt-svg-icon kt-svg-icon--brand"
                                            name="assignments"
                                        />
                                    </div>
                                    <div class="kt-notification__item-details">
                                        <div class="kt-notification__item-title">
                                            {{ assignment.courseWorkTitle }}
                                        </div>
                                        <div class="kt-notification__item-time">
                                            {{ assignment.googleCourseName }}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mt-4 text-center">
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-brand"
                                    aria-label="Go back"
                                    @click="back()"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="kt-portlet__body pt-4">
                    <div class="row form-group validated">
                        <!-- first row -->
                        <div class="col-md-3">
                            <span class="kt-font-danger">* </span>
                            <label>{{ $t('pages.teacher.assignments.markingPeriod') }}</label>
                            <select
                                v-model="courseWork.schoolTermMarkingPeriodId"
                                class="form-control"
                                :class="{ 'is-invalid': errors.markingPeriod }"
                            >
                                <option
                                    v-for="option in markingPeriodSelect"
                                    :key="option.value"
                                    :value="option.value"
                                >
                                    {{ option.text }}
                                </option>
                            </select>
                            <span
                                v-if="errors.markingPeriod"
                                class="form-text invalid-feedback"
                            >
                                {{ errors.markingPeriod }}
                            </span>
                        </div>
                        <div class="col-md-3">
                            <label class="w-100">
                                <span class="kt-font-danger">* </span>
                                {{ $t('pages.teacher.assignments.category') }}

                            </label>
                            <select
                                v-model="courseWork.gradeTemplateCategoryId"
                                class="form-control"
                                :class="{ 'is-invalid': errors.courseWorkCategory }"
                            >
                                <option
                                    v-for="t in gradeTemplateCategories"
                                    :key="`gradeTemplateCategories_${t.gradeTemplateCategoryId}`"
                                    :value="t.gradeTemplateCategoryId"
                                >
                                    {{ t.categoryName }}
                                </option>
                            </select>
                            <span
                                v-if="errors.courseWorkCategory"
                                class="form-text invalid-feedback"
                            >
                                {{ errors.courseWorkCategory }}
                            </span>
                        </div>
                        <div class="col-md-3">
                            <label>Due Date</label>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-form-datepicker
                                        v-model="courseWork.dueDate"
                                        button-only
                                        autocomplete="off"
                                        reset-button
                                        locale="en-US"
                                    />
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="courseWork.dueDate"
                                    type="date"
                                    class="form-control kt-input"
                                    :class="{ 'is-invalid': errors.dueDate }"
                                    autocomplete="off"
                                />
                            </b-input-group>
                        </div>
                        <div class="col-md-3">
                            <!-- Due Time -->
                            <label>Due Time</label>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-form-timepicker
                                        v-model="courseWork.dueTime"
                                        :show-seconds="false"
                                        hour12
                                        button-only
                                        reset-button
                                        locale="en"
                                    />
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="courseWork.dueTime"
                                    type="time"
                                    :class="errors.dueDate ? 'is-invalid form-control kt-input' : 'form-control kt-input'"
                                    autocomplete="off"
                                />
                            </b-input-group>
                        </div>

                        <div
                            v-if="errors.dueDate"
                            class="col-12"
                        >
                            <span class="form-text invalid-feedback">
                                {{ errors.dueDate }}
                            </span>
                        </div>

                        <!-- second row -->

                        <div class="col-md-3">
                            <!-- courseWorkTypes -->
                            <span class="kt-font-danger">* </span>
                            <label>Type</label>
                            <select
                                v-model="courseWork.courseWorkType"
                                class="form-control"
                            >
                                <option
                                    v-for="t in courseWorkTypes"
                                    :key="`courseWorkTypes_${t.value}`"
                                    :value="t.value"
                                >
                                    {{ t.display }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <span class="kt-font-danger">* </span>
                            <label>{{ $t('pages.teacher.assignments.weight') }}</label>
                            <input
                                v-model="courseWork.courseWorkWeight"
                                class="form-control"
                                :class="{ 'is-invalid': errors.weight }"
                                type="text"
                            >
                            <div
                                v-if="errors.weight"
                                class="form-text invalid-feedback"
                            >
                                {{ errors.weight }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <span class="kt-font-danger">* </span>
                            <label>{{ $t('pages.teacher.assignments.maxPoints') }}</label>
                            <input
                                v-model="courseWork.maxPoints"
                                class="form-control"
                                :class="{ 'is-invalid': errors.maxPoints }"
                                type="text"
                            >
                            <div
                                v-if="errors.maxPoints"
                                class="form-text invalid-feedback"
                            >
                                {{ errors.maxPoints }}
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label>{{ $t('generic.labels.topic') }}</label>
                            <select
                                v-model="courseWork.extTopicId"
                                class="form-control"
                            >
                                <option
                                    v-for="t in googleTopics"
                                    :key="`googleCourseTopic_${t.googleCourseTopicId}`"
                                    :value="t.extTopicId"
                                >
                                    {{ t.topicName }}
                                </option>
                            </select>
                        </div>
                        <!-- third row -->
                        <div class="col-md-12">
                            <label v-if="courseWork.courseWorkType == 'MULTIPLE_CHOICE_QUESTION'">
                                <span class="kt-font-danger">* </span>
                                Multiple Choice Question
                            </label>
                            <label v-else-if="courseWork.courseWorkType == 'SHORT_ANSWER_QUESTION'">
                                <span class="kt-font-danger">* </span>
                                Short Answer Question
                            </label>
                            <label v-else>
                                <span class="kt-font-danger">* </span>
                                {{ $t('pages.teacher.assignments.title') }}
                            </label>

                            <input
                                v-model="courseWork.courseWorkTitle"
                                class="form-control"
                                :class="{ 'is-invalid': errors.courseWorkTitle }"
                                type="text"
                            >
                            <div
                                v-if="errors.courseWorkTitle"
                                class="form-text invalid-feedback"
                            >
                                {{ errors.courseWorkTitle }}
                            </div>
                        </div>

                        <!-- fourth row -->
                        <div
                            v-if="courseWork.courseWorkType == 'MULTIPLE_CHOICE_QUESTION'"
                            class="col-12 pt-5"
                        >
                            <div
                                v-for="(choice, idx) in courseWork.multipleChoiceQuestion.choices"
                                :key="`choice_${idx}`"
                                class="form-group row"
                            >
                                <div class="col-md-1 d-none d-sm-block">
                                    <span
                                        class="kt-badge kt-badge--primary kt-badge--dot pull-right"
                                        style="opacity: 0.5; margin-top: 15px;"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <div class="kt-input-icon kt-input-icon--right">
                                        <input
                                            v-model="choice.text"
                                            type="text"
                                            class="form-control"
                                            :class="{ 'is-invalid': false }"
                                            :placeholder="`Multiple Choice Option ${idx + 1}`"
                                        >
                                        <a
                                            href="#"
                                            class="kt-input-icon__icon kt-input-icon__icon--right"
                                            @click.stop.prevent="removeChoiceAtIndex(idx)"
                                        >
                                            <span><i class="la la-close" /></span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-5" />
                            </div>
                        </div>
                        <template v-else-if="courseWork.courseWorkType == 'ASSIGNMENT'">
                            <div class="col-12">
                                <!-- Description -->
                                <label>Assignment Description</label>
                                <textarea
                                    v-model="courseWork.courseWorkDescription"
                                    class="form-control"
                                    rows="3"
                                />
                            </div>
                        </template>

                        <!-- fifth row -->
                        <div class="col-md-3">
                            <label>{{ $t('generic.labels.state') }}</label>
                            <select
                                v-model="courseWork.courseWorkState"
                                class="form-control"
                            >
                                <option
                                    v-for="state in courseWorkStates"
                                    :key="state.value"
                                    :value="state.value"
                                >
                                    {{ $t(`pages.teacher.assignments.${state.display.toLowerCase()}`) }}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3">
                            <!-- Publish Date -->
                            <label>Publish Date</label>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-form-datepicker
                                        v-model="courseWork.publishDate"
                                        button-only
                                        autocomplete="off"
                                        reset-button
                                        locale="en-US"
                                    />
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="courseWork.publishDate"
                                    type="date"
                                    class="form-control kt-input"
                                    :class="{ 'is-invalid': errors.publishDate }"
                                    autocomplete="off"
                                />
                            </b-input-group>
                        </div>
                        <div class="col-md-3">
                            <!-- Publish Time -->
                            <label>Publish Time</label>
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-form-timepicker
                                        v-model="courseWork.publishTime"
                                        :show-seconds="false"
                                        hour12
                                        reset-button
                                        button-only
                                        locale="en"
                                    />
                                </b-input-group-prepend>
                                <b-form-input
                                    v-model="courseWork.publishTime"
                                    type="time"
                                    :class="errors.publishDate ? 'is-invalid form-control kt-input' : 'form-control kt-input'"
                                    autocomplete="off"
                                />
                            </b-input-group>
                        </div>

                        <div
                            v-if="errors.publishDate"
                            class="col-12"
                        >
                            <span class="form-text invalid-feedback">
                                {{ errors.publishDate }}
                            </span>
                        </div>

                        <!-- sixth row -->
                        <div class="col-12 pt-4">
                            <label class="kt-font-bold w-100">
                                Assign to Google Courses:
                                <span class="form-text text-muted pull-right">
                                    <a
                                        href="#"
                                        @click.stop.prevent="selectAll()"
                                    >All</a> |
                                    <a
                                        href="#"
                                        @click.stop.prevent="selectNone()"
                                    >None</a> |
                                    <a
                                        href="#"
                                        @click.stop.prevent="selectInvert()"
                                    >Invert</a>
                                </span>
                            </label>
                            <div class="kt-checkbox-inline">
                                <label
                                    v-for="(course, idx) in selectedGoogleCourses"
                                    :key="`label_${idx}_${course.googleCourseId}`"
                                    class="kt-checkbox col-xl-2 col-lg-3 col-md-4 col-sm-6 no-wrap-line"
                                    :class="course.disabled ? 'kt-checkbox--primary' : 'kt-checkbox--success'"
                                >
                                    <input
                                        v-model="course.selected"
                                        :disabled="course.disabled"
                                        type="checkbox"
                                    >

                                    {{ course.googleCourseName }}

                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__foot">
                    <div class="kt-form__actions pull-right pb-3">
                        <button
                            type="button"
                            class="btn btn-primary"
                            :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                            @click.stop.prevent="saveAssignment(courseWork.googleCourseWorkId)"
                        >
                            Create Assignment{{ selectedGoogleCourses.filter((course) => course.selected).length == 1 ?
                                '' : 's' }}
                        </button>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</div>
<div v-else>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet__body">
            <div
                class="alert alert-outline-primary"
                role="alert"
            >
                <div class="alert-icon">
                    <i class="flaticon-warning" />
                </div>
                <div class="alert-text">
                    This course is not linked to google classroom.
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import deepcopy from 'deepcopy';
import Types from '../../store/Types';
import * as network from '../../network';

import googleCourseMixins from '../../store/mixins/googleCourseMixins';
import courseMixins from '../../store/mixins/courseMixins';
import portfolioMixins from '../../store/mixins/portfolioMixins';
import teacherMixins from '../../store/mixins/teacherMixins';
import studentMixins from '../../store/mixins/studentMixins';

export default Vue.extend({
    name: 'GoogleCourseAssignmentCreate',
    mixins: [
        portfolioMixins,
        teacherMixins,
        googleCourseMixins,
        courseMixins,
        studentMixins,
    ],
    data() {
        return {
            selectedGoogleCourses: [],
            createdAssignments: [],
            saving: false,
            courseWork: {
                assigneeMode: 'ALL_STUDENTS',
                courseWorkType: 'ASSIGNMENT',
                courseWorkState: 'PUBLISHED',
                courseWorkTitle: null,
                courseWorkDescription: null,
                submissionModificationMode: 'MODIFIABLE_UNTIL_TURNED_IN',
                extTopicId: null,
                courseWorkWeight: 1,
                gradeTemplateCategoryId: 0,
                schoolTermMarkingPeriodId: null,
                averageExclusiveForMp: true,
                maxPoints: 100,
                associatedWithDeveloper: true,
                dueDate: null,
                dueTime: null,
                publishDate: null,
                publishTime: null,
                multipleChoiceQuestion: {
                    choices: [{
                        text: '',
                    }],
                },
            },
            errors: {
                courseWorkTitle: null,
                courseWorkCategory: null,
                courses: null,
                weight: null,
                maxPoints: null,
                publishDate: null,
                dueDate: null,
                markingPeriod: null,
            },
            courseWorkTypes: [
                {
                    value: 'ASSIGNMENT',
                    display: 'Assignment',
                },
                {
                    value: 'SHORT_ANSWER_QUESTION',
                    display: 'Short Answer Question',
                },
                {
                    value: 'MULTIPLE_CHOICE_QUESTION',
                    display: 'Multiple Choice Question',
                },
            ],
            courseWorkStates: [
                {
                    value: 'PUBLISHED',
                    display: 'Published',
                },
                {
                    value: 'DRAFT',
                    display: 'Draft',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            googleCourseTeachers: (state) => state.database.googleCourseTeachers,
            googleCourseCourseSections: (state) => state.database.googleCourseCourseSections,
            courseSectionGradeTemplates: (state) => state.database.courseSectionGradeTemplates,
            googleCourseGradeCategories: (state) => state.database.googleCourseGradeCategories,
            googleCourseTopics: (state) => state.database.googleCourseTopics,
            markingPeriods: (state) => state.database.markingPeriods,
            googleCourseWork: (state) => state.database.googleCourseWork,
            gradeTemplates: (state) => state.database.gradeTemplates,
            showQuickPanel: (state) => state.quickPanel.open,
        }),
        courseSection() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        googleCourseId() {
            if (!this.googleCourse) return 0;
            return this.googleCourse.googleCourseId;
        },
        schoolStaffId() {
            if (!this.teacher) return 0;
            return this.teacher.schoolStaffId;
        },
        currentCourse() {
            const { googleCourseId } = this;
            return this.$store.state.database.googleCourses.find((a) => a.googleCourseId == googleCourseId) || null;
        },
        googleCategories() {
            if (!this.googleCourseId) return [];
            const { googleCourseId } = this;
            const googleCourseGradeCategories = this.googleCourseGradeCategories
                .filter((a) => a.googleCourseId == googleCourseId);

            googleCourseGradeCategories.unshift({
                googleCourseGradeCategoryId: 0,
                extGradeCategoryId: null,
                gradeCategoryName: 'No Category',
            });

            return googleCourseGradeCategories;
        },
        googleTopics() {
            const { googleCourseId } = this;
            const googleCourseTopics = this.googleCourseTopics
                .filter((a) => a.googleCourseId == googleCourseId);

            googleCourseTopics.unshift({
                googleCourseTopicId: 0,
                extTopicId: null,
                topicName: 'No Topic',
            });

            return googleCourseTopics;
        },
        googleCourses() {
            const { schoolStaffId } = this;

            if (!schoolStaffId) return [];

            const courseSectionIds = this.$store.state.database
                .courseSectionTeachers.filter((row) => row.schoolStaffId == schoolStaffId)
                .map((row) => row.courseSectionId);

            const googleCourseIds = this.$store.state.database
                .googleCourseCourseSections.filter((row) => courseSectionIds.includes(row.courseSectionId))
                .map((row) => row.googleCourseId);

            const googleCourses = this.$store.state.database
                .googleCourses.filter((row) => googleCourseIds.includes(row.googleCourseId));

            return googleCourses;
        },
        gradeTemplateCategories() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return [];

            const categories = gradeTemplate.categories.slice();
            categories.unshift({
                gradeTemplateCategoryId: 0,
                categoryName: 'No Category',
            });

            return categories;
        },
        googleCourseWorks() {
            const { $store, googleCourseId } = this;
            return $store.state.database.googleCourseWork.filter((g) => g.googleCourseId == googleCourseId);
        },
        gradeTemplate() {
            const { courseSectionId } = this.courseSection;

            const t = this.courseSectionGradeTemplates.find((c) => c.courseSectionId == courseSectionId);
            if (!t) return null;
            return this.gradeTemplates.find((gt) => gt.gradeTemplateId == t.gradeTemplateId);
        },
        markingPeriodSelect() {
            const { markingPeriods } = this;
            const mps = markingPeriods.filter((mp) => !mp.deleted).map((mp) => ({
                value: mp.schoolTermMarkingPeriodId,
                text: mp.markingPeriod,
            }));
            mps.unshift({
                value: null,
                text: 'No Marking Period',
            });
            return mps;
        },
        allSelected: {
            set(selected) {
                const { googleCourseId } = this;
                if (selected) {
                    this.selectedGoogleCourses = this.googleCourses.map((c) => {
                        const course = c;
                        course.selected = true;
                        return course;
                    });
                } else {
                    this.selectedGoogleCourses = this.googleCourses.map((c) => {
                        const course = c;
                        course.selected = false;
                        if (googleCourseId == course.googleCourseId) {
                            course.selected = true;
                        }
                        return course;
                    });
                }
            },
            get() {
                return this.selectedGoogleCourses.every((c) => c.selected);
            },
        },
    },
    watch: {
        'courseWork.multipleChoiceQuestion.choices': {
            handler() {
                const { choices } = this.courseWork.multipleChoiceQuestion;
                const submitted = choices.filter((c) => (c.text || '').trim() !== '');
                if (submitted.length == choices.length) {
                    choices.push({
                        text: '',
                    });
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ...mapMutations(['openQuickPanelForGoogleCourse']),
        removeChoiceAtIndex(index) {
            const { choices } = this.courseWork.multipleChoiceQuestion;
            choices.splice(index, 1);
        },
        openAssignment(cw) {
            if (cw.alternateLink) {
                window.open(cw.alternateLink, '_blank');
            } else {
                this.showNotification('Google doesnt provide a link for this assignment until it is published.');
            }
        },
        populate() {
            if (!this.currentCourse) return [];
            const { googleCourseId } = this;
            const { googleCourseCourseSections, courseSections } = this.$store.state.database;

            this.selectedGoogleCourses = this.googleCourses.map((c) => {
                const course = c;
                course.selected = googleCourseId == course.googleCourseId;
                course.schoolCourses = googleCourseCourseSections
                    .filter((row) => row.googleCourseId == course.googleCourseId)
                    .map((row) => {
                        const courseSection = courseSections.find((i) => i.courseSectionId == row.courseSectionId) || null;
                        return courseSection ? courseSection.extCourseSectionId : null;
                    })
                    .filter((i) => i !== null);

                return course;
            });

            if (this.googleCourseGradeCategories.length == 0 || this.googleCourseTopics.length == 0) {
                this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE_GRADE_CATEGORIES, googleCourseId);
                this.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSE_TOPICS, googleCourseId);
            }
        },
        openGoogleCourse(googleCourseId) {
            const params = { googleCourseId };
            if (this.showQuickPanel) return this.closeQuickPanel();
            this.openQuickPanelForGoogleCourse(params);
        },
        isValid() {
            const { courseWork, errors, selectedGoogleCourses } = this;

            errors.courseWorkTitle = null;
            errors.courseWorkCategory = null;
            errors.courses = null;
            errors.weight = null;
            errors.maxPoints = null;
            errors.publishDate = null;
            errors.dueDate = null;
            errors.markingPeriod = null;

            let isValid = true;
            if (!courseWork.courseWorkTitle || courseWork.courseWorkTitle.length == 0) {
                errors.courseWorkTitle = 'Title is required';
                isValid = false;
            }
            if (!courseWork.gradeTemplateCategoryId) {
                errors.courseWorkCategory = 'Category selection is required';
                isValid = false;
            }
            if (selectedGoogleCourses.filter((course) => course.selected).length == 0) {
                errors.courses = 'You must select at least one section';
                isValid = false;
            }

            if (!courseWork.courseWorkWeight) {
                errors.weight = 'Weight is required';
                isValid = false;
            }

            if (!courseWork.schoolTermMarkingPeriodId) {
                errors.markingPeriod = 'Marking Period is required';
                isValid = false;
            }

            if (!/^\d+$/.test(courseWork.courseWorkWeight)) {
                if (!errors.weight) {
                    errors.weight = 'Weight must be a positive number';
                    isValid = false;
                }
            }
            if (!courseWork.maxPoints) {
                errors.maxPoints = 'Max Points is required';
                isValid = false;
            }
            if (!/^\d+$/.test(courseWork.maxPoints)) {
                if (!errors.maxPoints) {
                    errors.maxPoints = 'Max Points must be a positive number';
                    isValid = false;
                }
            }

            if (courseWork.dueTime && !courseWork.dueDate) {
                errors.dueDate = 'If submitting a time, you must also submit a date';
                isValid = false;
            } else if (courseWork.dueDate) {
                const format = 'YYYY-MM-DD HH:mm:ss';
                const dateTime = moment(`${courseWork.dueDate} ${courseWork.dueTime || '23:59:59'}`, format);

                if (dateTime.format(format) !== `${courseWork.dueDate} ${courseWork.dueTime || '23:59:59'}`) {
                    errors.dueDate = 'Please pick a valid due date and time';
                    isValid = false;
                } else if (moment(courseWork.dueDate).isBefore(moment(), 'day')) {
                    errors.dueDate = `Unfortunately, google doesnt allow us to set a due date that is in the past.
                    You can alter the assignment after it is created and change the date in google classroom.`;
                    isValid = false;
                }
            }

            if (courseWork.publishTime && !courseWork.publishDate) {
                errors.publishDate = 'If submitting a time, you must also submit a date';
                isValid = false;
            } else if (courseWork.publishDate) {
                const format = 'YYYY-MM-DD HH:mm:ss';
                const dateTime = moment(`${courseWork.publishDate} ${courseWork.publishTime || '23:59:59'}`, format);

                if (dateTime.format(format) !== `${courseWork.publishDate} ${courseWork.publishTime || '23:59:59'}`) {
                    errors.publishDate = 'Please pick a valid publish date and time';
                    isValid = false;
                } else if (dateTime.isBefore(moment(), 'minute')) {
                    errors.publishDate = 'Publish date cannot be in the past.';
                    isValid = false;
                }
            }

            return isValid;
        },
        saveAssignment() {
            const v = this;
            const courseWork = deepcopy(v.courseWork);

            if (!v.isValid()) return;

            const dateFormat = 'YYYY-MM-DD HH:mm:ss';
            // format dueDate with time
            if (courseWork.dueDate) {
                const dueDate = moment(`${courseWork.dueDate} ${courseWork.dueTime || '23:59:59'}`, dateFormat).utc();
                courseWork.dueDate = dueDate.format(dateFormat);
            }

            courseWork.topic = null;
            if (courseWork.extTopicId) {
                courseWork.topic = this.googleTopics
                    .find((t) => t.extTopicId == courseWork.extTopicId) || null;
            }

            // format publishDate with time
            if (courseWork.publishDate) {
                const publishDate = moment(`${courseWork.publishDate} ${courseWork.publishTime || '00:00:00'}`, dateFormat).utc();
                courseWork.publishDate = publishDate.format(dateFormat);
                courseWork.courseWorkState = 'DRAFT';
            }

            if (courseWork.courseWorkType == 'MULTIPLE_CHOICE_QUESTION') {
                const choices = v.courseWork.multipleChoiceQuestion.choices
                    .map((c) => c.text)
                    .filter((c) => c.length > 0);

                courseWork.multipleChoiceQuestion = {
                    choices,
                };
            } else {
                courseWork.multipleChoiceQuestion = null;
            }

            delete courseWork.publishTime;
            delete courseWork.dueTime;
            delete courseWork.extTopicId;

            v.createdAssignments = [];
            if (v.saving) return;
            v.saving = true;

            const googleCourseIds = v.selectedGoogleCourses
                .filter((x) => x.selected)
                .map((x) => x.googleCourseId);

            const { schoolId, schoolTermId } = v.user.school;
            const { schoolStaffId } = v;

            const { $store } = v;

            const callback = function (err, resp) {
                v.saving = false;
                if (err) return v.showError(err);
                const { googleCourseWork } = resp;
                v.createdAssignments = googleCourseWork.map((c) => {
                    const course = v.selectedGoogleCourses.find((x) => x.googleCourseId == c.googleCourseId);
                    return {
                        ...c,
                        googleCourseName: course.googleCourseName,
                    };
                });
                const params = {
                    url: { schoolId, schoolTermId, schoolStaffId },
                };
                network.googleCourseWork.getCourseWorkForTeacher(params, (refreshErr, res) => {
                    if (refreshErr) return v.showError(refreshErr);
                    $store.commit(Types.mutations.SET_DB_GOOGLE_COURSE_WORK, res.googleCourseWork);
                });
            };

            $store.dispatch(Types.actions.ADD_GOOGLE_COURSE_WORK, {
                googleCourseWork: courseWork,
                googleCourseIds,
                callback,
            });
        },
        back() {
            this.$router.back();
        },
        selectNone() {
            const googleCourses = this.selectedGoogleCourses.map((gc) => {
                const course = gc;
                if (course.disabled) return course;
                course.selected = false;
                return course;
            });
            this.selectedGoogleCourses = [...googleCourses];
        },
        selectAll() {
            const googleCourses = this.selectedGoogleCourses.map((gc) => {
                const course = gc;
                if (course.disabled) return course;
                course.selected = true;
                return course;
            });
            this.selectedGoogleCourses = [...googleCourses];
        },
        selectInvert() {
            const googleCourses = this.selectedGoogleCourses.map((gc) => {
                const course = gc;
                if (course.disabled) return course;
                course.selected = !course.selected;
                return course;
            });
            this.selectedGoogleCourses = [...googleCourses];
        },
    },
});

</script>

<style scoped>
tbody label.kt-checkbox {
    width: 100%;
}

tbody label.kt-checkbox span {
    margin-top: 10px;
}

tbody .kt-checkbox.kt-checkbox--single {
    height: auto;
    margin-bottom: 0;
}

.sg-info-container {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    border-bottom: 1px #f0f0f0 solid;
}

.sg-info-item {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%
}

.sg-info-item.first {
    padding: 0px 10px 0px 0px;
    border-right: 1px #e2e5ec solid;
}

.sg-info-item.last {
    padding: 0px 0px 0px 10px;
    border-left: 1px #e2e5ec solid;
}

span.sg-info-title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 20px;
}

span.sg-info-detail {
    font-weight: 300;
    font-size: 1.1rem;
}

.kt-widget28__tab-item {
    padding-bottom: 20px;
}

span.sg-switch {
    display: flex;
    width: 100%;
    padding: 0.65rem 0rem 0rem 0rem;
    flex-direction: row-reverse;
}

td.delta-warning {
    vertical-align: middle;
    padding-right: 1em;
    font-size: 20px;
}

.error {
    border: 1px red solid;
}

.form-group.validated>div {
    padding-top: 1.2rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

.no-wrap-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
